import React from 'react'
import SEO from '../../components/seo'
import { useTranslations } from '../../hooks/use-translations'
import NewsSection from '../../components/NewsSection'

import * as s from '../news.module.scss'

const CheckerPage: React.FC = () => {
  const { t } = useTranslations()
  const title = 'Checker'
  const description = t('checker_tools_description')
  const content = t('checker_tools_content')

  return (
    <>
      <SEO title={title} description={description} />
      <NewsSection title={title} rayContent maxContent classTitle="pt-5">
        <p className={s.text}>{content}</p>
      </NewsSection>
    </>
  )
}

export default CheckerPage
